:root {
	--header-height: 70px;
}

@font-face {
	font-family: Oswald-Light;
	src: url(./assets/fonts/Oswald-Light.ttf);
}

.app-window {
	padding-top: 110px;
}

.app-header {
	height: var(--header-height);
	background-color: #fafafa;
	transition: 0.5s ease;
	box-shadow: 0px 0px 5px 1px #bbb;
}

.visible {
	visibility: visible;
	opacity: 1;
}

.hidden {
	visibility: hidden;
	opacity: 0;
	height: 0;
}

.app-header.top {
	background-color: transparent;
	box-shadow: none;
}

.header-btn.top {
	color: var(--light);
}

.header-btn.top:hover {
	color: var(--light);
	opacity: 0.8;
}

.header-btn {
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	color: var(--dark);
	font-family: Oswald-Light, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 20px;
}

.header-btn:hover {
	color: var(--dark);
	opacity: 0.8;
}

.header-btn-logo {
	font-size: 30px;
	letter-spacing: 0px;
}

.landing-logo {
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding-top: 150px;
	max-width: 40%;
	min-width: 200px;
	max-height: 90vh;
}

.catering-img {
	display: inline-block;
	padding: 0px;
	width: 50%;
	height: 50vh;
	object-fit: cover;
}

.catering-img-div {
	width: 100%;
	display: flex;
	justify-content: center;
}

.footer-address-link:hover {
	opacity: 0.7;
}

@media screen and (max-width: 1000px) {
	.landing-logo {
		max-width: 60%;
	}
}

@media screen and (max-width: 500px) {
	.landing-logo {
		max-width: 70%;
	}

	.catering-img-div {
		flex-direction: column;
	}

	.catering-img {
		width: 100%;
	}
}

@media screen and (max-width: 450px) {
	.menu-img {
		min-width: 100vw;
	}
}

.menu-img {
	width: 450px;
}

.school-catering-cont {
	width: 40%;
}

.menu-window {
	background-color: #eeeeee;
}

.landing-div {
	background-image: url("assets/bg/Yakiniku-70.jpg");
	top: -110px;
	z-index: -100;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.blur-div {
	backdrop-filter: blur(5px) brightness(80%);
	-webkit-backdrop-filter: blur(5px) brightness(85%);
	height: 100%;
	width: 100%;
}

.contact-map {
	width: 350px;
}

@media screen and (max-width: 350px) {
	.contact-map {
		width: 100%;
	}
}

.btn {
	height: fit-content;
}

.btn:focus {
	box-shadow: none !important;
	outline: 5px auto -webkit-focus-ring-color !important;
}


.footer-socials-widget .fab:hover {
	background-color: #a2a8a3;
	color: #e6ede8;
	border-width: 0.25rem;
}

.footer-socials-widget .fab {
	transition: 0.5s ease;
	font-size: 2rem;
	background: transparent;
	color: #a2a8a3;
	padding: 0 0.5rem;
	margin: 0.3rem;
	text-align: center;
	display: inline;
	border-radius: 50%;
	line-height: 50px;
}

.socials-link:hover {
	text-decoration: none;
}

.footer-copyright .company-name {
	color: #a2a8a3;
}

.decorated {
	overflow: hidden;
	text-align: center;
	margin-bottom: 2rem;
}
.decorated > span {
	position: relative;
	display: inline-block;
	text-transform: uppercase;
}

.decorated > span:before,
.decorated > span:after {
	content: "";
	color: grey;
	position: absolute;
	top: 50%;
	border-bottom: 2px solid;
	width: 200px; /* half of limiter */
	margin: 0 20px;
}
.decorated > span:before {
	right: 100%;
}
.decorated > span:after {
	left: 100%;
}

.no-drag * {
	-webkit-user-drag: none;
}

.no-select * {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.new-store-cont {
	text-align: center;
	color: white;
}

.new-store-cont:hover { 
	color: white;
}

.new-store-cont:hover .new-store-text { 
	box-shadow: 0 0 0 4px white;	
	transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}